import React from 'react';
import InputFile from './InputFile';
import InputText from './InputText';

export default function Input(props){

    if(props.type === 'file'){

        return <InputFile {...props} />
    }

    return <InputText {...props} />;
}
