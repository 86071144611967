import React from 'react';
import {useLang, useTheme} from 'library/hooks';
import {Button} from 'library/components';
import styled from 'styled-components';

const Container = styled.div(() => `

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    z-index: 1000;
`);

const Box = styled.div(props => `

    background: ${props.theme.backgroundColor.core.primary};
    color: ${props.theme.color.core.primary};
    padding-top: ${props.theme.padding.medium};
    padding-right: ${props.theme.padding.medium};
    padding-bottom: 0;
    padding-left: ${props.theme.padding.medium};
    border-radius: ${props.theme.borderRadius.medium};
`);

const Text = styled.div(props => `

    padding-bottom: ${props.theme.padding.medium};
    text-align: center;
`);

const Columns = styled.div(props => `

    display: flex;
    flex-wrap: wrap;

    @media (min-width: ${props.theme.minWidth.tablet}){

        flex-wrap: nowrap;

        & > button:first-of-type {

            margin-right: ${props.theme.margin.smaller !== undefined ? props.theme.margin.smaller : `0.5em`};
        }

        & > button:last-of-type {

            margin-left: ${props.theme.margin.smaller !== undefined ? props.theme.margin.smaller : `0.5em`};
        }
    }
`);

function Confirm(props){

    const {translations} = useLang('confirm_modal', 'app');
    const {theme} = useTheme();

    if(props.value === false){

        return false;
    }

    let ok = translations.ok;

    if(props.translations !== undefined && props.translations.ok !== undefined){

        ok = props.translations.ok;
    }

    return (

        <Container>

            <Box>

                <Text>{props.value}</Text>

                <Columns>

                    <Button onClick={() => props.close(false)}>{translations.cancel}</Button>
                    <Button onClick={() => props.close(true)}>{ok}</Button> 

                </Columns>

            </Box>

        </Container>
    );
}

export default Confirm;
