import {useContext} from 'react';
import AppContext from 'library/AppContext';

export function useLoading(){

    const context = useContext(AppContext);

    return {

        setLoading: context.loading.setLoading,
        loading: context.loading.state
    };
}
