import {Validator} from 'library/helpers';
import {useState, useRef} from 'react';

export function useValidator(data, realtime){

    if(data === undefined){

        throw new Error('Validator data is not set: useValidator(data)');
    }

    // Real time validation

    if(realtime === undefined){

        realtime = false;
    }

    const [, setState] = useState();

    const ref = useRef({

        data: false,
        callback: false
    });

    const validator = new Validator(data);

    if(ref.current.callback !== false){

        if(realtime === false){

            if(ref.current.data !== false){
            
                validator.setData(ref.current.data); // Use old data
            }
        }

        ref.current.callback(validator); // Execute rules callback
    }

    function setRules(rules){

        ref.current.callback = rules;

        if(realtime === false){
        
            ref.current.data = new Map(data); // Save old data for non real time validation
        }

        validator.setData(data); // Update data
        validator.clearErrors(); // Clear old errors

        rules(validator); // Execute rules

        setState(true); // Trigger render
    }

    function getErrors(key){

        const errors = validator.results();

        if(key !== undefined){

            const error = errors.get(key);

            if(error === undefined){

                return false;
            }

            else {

                return error;
            }
        }

        return errors;
    }

    function getMessages(){

        const errors = getErrors();

        if(errors === false){

            return [];
        }

        return Validator.parseMessages(errors);
    }

    return {

        getErrors: getErrors,
        getMessages: getMessages,
        setRules: setRules
    }
}
