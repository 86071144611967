import React from 'react';
import styled from 'styled-components';
import Button from 'library/components/Button';
import {useLang} from 'library/hooks';

const Container = styled.div(props => `

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    z-index: 1000;
    padding: ${props.theme.padding.large};
`);

const Box = styled.div(props => `

    background: ${props.theme.backgroundColor.core.primary};
    padding: ${props.theme.padding.medium};
    border-radius: ${props.theme.borderRadius.medium};
    max-height: 100%;
    overflow: auto;

    & > div:last-of-type {

        margin-bottom: ${props.theme.margin.medium};
    }
`);

const Message = styled.div``;

function Alert(props){

    const {translations} = useLang('alert_modal', 'app');

    if(props.value === false){

        return false;
    }

    let message = props.value;

    if(Array.isArray(message) === false){

        message = [props.value];
    }

    return (

        <Container>

            <Box>

                {message.map((v, k) => <Message key={k}>{v}</Message>)}
                <Button style={{margin: 0}} onClick={() => props.close()}>{translations.ok}</Button>

            </Box>

        </Container>
    );
}

export default Alert;
