import React from 'react';
import styled from 'styled-components';
import {useRoute} from 'library/hooks';

const Link = styled.a(props => `

    font-size: 1em;

    &:link, &:visited, &active {

        color: ${props.theme.color.main.secondary};
    }

    &:hover {

        text-decoration: none;
    }
`);

export default (props) => {

    const {setRoute} = useRoute();

    function go(e){

        e.preventDefault();

        setRoute(props.to);
    }

    function open(e){

        e.preventDefault();

        window.open(props.href);
    }

    function custom(e){

        e.preventDefault();

        props.onClick();
    }

    if(props.href !== undefined){

        if(props.target === '_blank'){

            return <Link href={props.href} rel="noopener noreferrer" onClick={open}>{props.children}</Link>;
        }

        return <Link href={props.href}>{props.children}</Link>;
    }

    else if(props.to !== undefined){

        return <Link href={props.to} onClick={go}>{props.children}</Link>;
    }

    else if(props.onClick !== undefined){

        return <Link href="" onClick={custom}>{props.children}</Link>;
    }

    return false;
}
