import {useContext, useEffect, useRef} from 'react';
import AppContext from 'library/AppContext';

export function useInit(callback){

    const context = useContext(AppContext);
    const query = context.route.route.query;
    const ref = useRef(query);

    useEffect(init, []);

    function init(){

        // Execute only if query has changed
        if(context.init.getInitStatus() !== ref.current){

            // Set current query as init status
            context.init.setInitStatus(query);
            // Call callback
            callback(context.init.setInitState);
        }
    }

    return {

        getInit: context.init.getInitState
    };
}
