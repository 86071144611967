import {useContext} from 'react';
import AppContext from 'library/AppContext';

export function useStores(){

    const context = useContext(AppContext);
    const {stores, setStores} = context.stores;

    function setOptions(store, action, data){

        if(data instanceof Map){

            // Convert Map to object
            data = Object.fromEntries(data.entries());
        }

        const options = {

            store: store,
            action: action,
            data: data
        };

        setStores(options);
    }

    return {

        stores: stores,
        setStores: setOptions
    };
}
