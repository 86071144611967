export const mainRoutes = [];

let currentGroup = false;

export function route(mixed, dependencies, subroutes){

    if(dependencies === undefined || dependencies === null || dependencies === false || dependencies === ''){

        dependencies = [];
    }

    else if(Array.isArray(dependencies) === false){

        dependencies = [dependencies];
    }

    dependencies.forEach((v, k) => {

        const [storeName, method] = v.split('@');

        dependencies[k] = {storeName: storeName, method: method};
    });

    const [route, component] = mixed.split('@');

    const matched = [];

    if(subroutes !== undefined){

        subroutes.forEach(subroute => {

            mainRoutes.forEach((v, k) => {

                if(v.path === subroute){

                    matched.push(v);
                    mainRoutes.splice(k, 1);
                }
            });
        });
    }

    mainRoutes.push({group: currentGroup, path: route, component: component, dependencies: dependencies, subroutes: matched});

    return route;
}

export function group(name, callback){

    currentGroup = name;

    callback();

    currentGroup = false;
}
