import React, {useState, useEffect, useRef, useReducer, useMemo} from 'react';
import AppProvider from 'library/AppProvider';
import request from 'library/request';
import parseRoutes from 'library/parseRoutes';
import load from 'library/load';
import {mainRoutes, route, group} from 'library/router';
import {ThemeProvider} from 'styled-components';
import 'library/styles/reset.css';
import 'library/fonts/font-awesome/css/all.min.css';
import config from 'config';
import {merge, sort, time, parseNumber} from 'library/helpers';
import setStores from 'library/setStores';

const Loading = config.components.loading;
const Preloading = config.components.preloading;
const Alert = config.components.alert;
const Confirm = config.components.confirm;

export default function App(){

    const APP_STATUS_BOOTING = 'booting';
    const APP_STATUS_LOADING = 'loading';
    const APP_STATUS_COMPLETE = 'complete';

    useEffect(() => {

        // Prevent scroll problems with history API

        if(window.history.scrollRestoration){

            window.history.scrollRestoration = 'manual';
        }

        if(engine.current.boot === true){

            // Boot only once

            engine.current.boot = false;

            function saveInstallEvent(e){
            
                e.preventDefault();
                engine.current.setState({install: e});
            }

            // Listen install prompt

            window.addEventListener('beforeinstallprompt', saveInstallEvent);

            // Listen color scheme change

            window.matchMedia('(prefers-color-scheme: dark)').addListener(() => {

                if(localStorage.getItem('mode') === null && config.defaultTheme === undefined){

                const mode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

                    setMode(mode);
                }
            });

            // Load required files before start loading            

            engine.current.imports.set(engine.current.lang + '@lang', {file: engine.current.lang + '.json', type: 'lang'});

            const theme = getThemeName();

            engine.current.imports.set(theme + '@theme', {file: theme, type: 'theme'});

            const modules = [];

            engine.current.imports.forEach((importable, k) => {

                if(importable.type === 'theme'){

                    modules.push(import(/* webpackChunkName: "[request]" */ 'themes/' + importable.file).then(

                        module => {

                            importable.module = module.default; // Add imported module
                            engine.current.imports.set(k, importable);
                        },

                        error => {

                            throw new Error(error);
                        }
                    ));
                }

                else if(importable.type === 'lang'){

                    modules.push(import(/* webpackChunkName: "[request]" */ 'lang/' + importable.file).then(

                        module => {

                            importable.module = module.default; // Add imported module
                            engine.current.imports.set(k, importable);
                        },

                        error => {

                            throw new Error(error);
                        }
                    ));
                }
            });

            Promise.all(modules).then(()  => {

                engine.current.setState({status: APP_STATUS_LOADING});

                // If access token exists set groups

                if(localStorage.getItem('access_token') !== null){

                    const payload = engine.current.getPayload();
                    const timestamp = parseNumber(time.set().getDate({}, "timestamp"));

                    // Add groups if token is not expired

                    if(timestamp < payload.exp && payload.groups !== undefined){

                        engine.current.setState({groups: payload.groups});
                    }
                }

                // Loading

                load(engine, app.path).then(

                    stores => {

                        engine.current.setState({

                            status: APP_STATUS_COMPLETE,
                            stores: stores
                        });

                        if(engine.current.isForbidden(app.path) === true){

                            engine.current.openForbiddenModal();
                        }
                    },

                    error => {

                        engine.current.setState({status: APP_STATUS_COMPLETE, loadingError: true});
                        engine.current.handleError(error);
                    }
                );

                 // Listen history API state change

                window.onpopstate = () => {

                    engine.current.setState({loading: {isLoading: true, progress: null}});

                    const path = window.location.pathname;

                    // Load on pop state

                    load(engine, path).then(

                        stores => {

                            if(engine.current.isForbidden(path) === true){
                            
                                engine.current.openAlertModal(getLang('system').messages.forbidden, () => {

                                    engine.current.openForbiddenModal();
                                });

                                engine.current.setState({loading: {isLoading: false, progress: null}});
                            }

                            else {

                                engine.current.setState({

                                    path: path,
                                    stores: stores,
                                    loading: {isLoading: false, progress: null},
                                    init: new Map
                                });
                            }
                        },

                        error => {

                            engine.current.handleError(error);
                        }
                    );
                }
            });
        }

        executeActions();
    });

    function executeActions(){

        if(engine.current.actions.length !== 0){

            const action = engine.current.actions.shift();

            if(action.type === 'openModal'){

                openModal(action.uuid, action.component);
            }

            else if(action.type === 'closeModal'){

                closeModal(action.uuid);
            }
        }
    }

    function openModal(uuid, Component){

        const modals = engine.current.state.modals;

        modals.set(uuid, Component);
        engine.current.setState({modals: modals});
    }

    function closeModal(uuid){

        const modals = engine.current.state.modals;

        modals.delete(uuid);
        engine.current.setState({modals: modals});
    }

    const initialState = {

        path: window.location.pathname,
        status: APP_STATUS_BOOTING,
        loading: {isLoading: false, progress: null},
        groups: [], // Current users access rights
        loadingError: false,
        sharedState: new Map,
        modals: new Map,
        action: null,
        init: new Map,
        install: null,
        stores: new Map // New stores
    };

    const [app, setAppState] = useState(initialState);
    const [alertModal, setAlertModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [lang, setLangState] = useState(localStorage.getItem('lang') === null ? config.defaultLang : localStorage.getItem('lang'));
    const [mode, setModeState] = useState(localStorage.getItem('mode') === null ? getDefaultMode() : localStorage.getItem('mode'));

    function getDefaultMode(){

        if(config.defaultMode === undefined){

            return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        }

        if(config.defaultMode !== 'light' && config.defaultMode !== 'dark'){

            throw new Error('Invalid default mode');
        }

        return config.defaultMode;
    }

    function setLang(lang){

        function set(){

            engine.current.lang = lang;
            setLangState(lang);
            localStorage.setItem('lang', lang);
        }

        const translations = [

            {importable: lang + '@lang', file: lang + '.json'},
            {importable: lang + '@' + engine.current.side, file: engine.current.side + '/' + lang + '.json'}
        ];

        const promises = [];

        translations.forEach(translation => {

            // Language is not yet loaded

            if(engine.current.imports.get(translation.importable) === undefined){

                if(engine.current.state.loading.isLoading !== true){

                    engine.current.setState({loading: {isLoading: true, progress: null}});
                }

                promises.push(import(/* webpackChunkName: "[request]" */ 'lang/' + translation.file).then(

                    module => {

                        engine.current.imports.set(translation.importable, {from: translation.file, module: module.default});
                    },

                    error => {

                        return Promise.reject(error);
                    }
                ));
            }
        });

        Promise.all(promises).then(

            () => {

                engine.current.setState({loading: {isLoading: false, progress: null}});
                set();
            },

            rejected => {

                throw new Error(rejected);
            }
        );
    }

    function setMode(mode){
      
        const file = engine.current.side + '.' + mode;
        const importable = engine.current.side + '.' + mode + '@theme';

        // Load theme module if it's not already loaded        

        function changeMode(){

            engine.current.mode = mode;
            setModeState(mode);
            localStorage.setItem('mode', mode);
        }
        
        if(engine.current.imports.get(importable) === undefined){

            import(/* webpackChunkName: "[request]" */ 'themes/' + file).then(

                module => {

                    engine.current.imports.set(importable, {

                        from: file,
                        module: module.default
                    });

                    changeMode();
                },

                errors => {

                    console.warn('Theme loading:', errors);
                }
            );
        }

        else {

            changeMode();
        }
    }

    function getThemeName(){

        const side = engine.current.side !== undefined ? engine.current.side : config.defaultTheme;
        const theme = side + '.' + mode;

        return theme;
    }

    const engine = useRef({

        lang: lang, // Independent state
        mode: mode, // Independent state
        imports: new Map, // Module cache
        routes: mainRoutes,
        alertModalCallback: false,
        confirmModalCallback: false,
        state: initialState, // Copy of a latest state
        prev: {state: {}},
        forbidden: [],
        boot: true,
        side: undefined,
        init: new Map(),
        actions: [],

        // Use ref to merge state always with the latest state even from asynchronous callbacks or events

        setState: values => {

            const state = {...engine.current.state, ...values};

            engine.current.state = state;

            setAppState(prev => {

                engine.current.prev.state = prev;

                return state;
            });
        },

        // Preload path before changing it

        setRoute: (path, options) => {

            const [clean_path] = path.split('?'); // Handle path with question mark

            if(options === undefined){

                options = {};
            }

            engine.current.setState({loading: {isLoading: true, progress: null}, loadingError: false}); // Reset possible previous loading error

            // Load by request
            load(engine, clean_path, options).then(

                stores => {

                    // Set props to pass

                    if(options.data !== undefined){

                        engine.current.props = {

                            path: clean_path,
                            data: options.data
                        };
                    }

                    else {
                        
                        delete engine.current.props;
                    }

                    if(engine.current.isForbidden(clean_path) === true){

                        engine.current.openForbiddenModal();

                        engine.current.setState({loading: {isLoading: false, progress: null}});
                    }

                    else {

                        // When path is changed using browsers back or forward button
                        
                        const currentPath = window.location.pathname + window.location.search;

                        if(path !== currentPath){

                            window.history.pushState(path, document.title, path);
                        }

                        // Switch view
                        engine.current.setState({

                            path: clean_path,
                            loading: {isLoading: false, progress: null},
                            init: new Map,
                            stores: stores
                        });

                        document.getElementById('root').scrollTop = 0;
                    }

                    engine.current.forbidden = [];
                },

                error => {

                    engine.current.setState({loading: {isLoading: false, progress: null}});
                    engine.current.handleError(error, () => engine.current.setRoute(clean_path));
                }
            );
        },

        // Handle forbidden

        setForbidden: path => {

            if(engine.current.forbidden.indexOf(path) === -1){

                engine.current.forbidden.push(path);
            }
        },

        isForbidden: path => {

            if(engine.current.forbidden.indexOf(path) === -1){

                return false;
            }

            return true;
        },

        // Handle error

        handleError: (error, callback) => {

            if(error.badRequest === true){

                engine.current.openAlertModal(getLang('system').messages.bad_request);
            }

            else if(error.notFound === true){

                engine.current.openAlertModal(getLang('system').messages.not_found, () => engine.current.redirect());
            }

            else if(error.forbidden === true){

                engine.current.openForbiddenModal();
            }

            if(error.network === true){

                const message = getLang('system').messages.network_error;
                const button =  getLang('system').confirm_modal.try_again;

                if(callback === undefined){
                    
                    engine.current.openConfirmModal(message, () => location.reload(), button);
                }

                else {

                    engine.current.openConfirmModal(message, () => callback(), button);
                }
            }
        },

        // Handle alert modal

        openAlertModal: (value, callback) => {

            setAlertModal(value);

            if(callback !== undefined){

                engine.current.alertModalCallback = callback;
            }
        },

        closeAlertModal: () => {

            setAlertModal(false);

            if(engine.current.alertModalCallback !== false){

                engine.current.alertModalCallback();
                engine.current.alertModalCallback = false;
            }
        },

        // Handle confirm modal

        openConfirmModal: (message, callback, button) => {

            setConfirmModal({message: message, ok: button});

            if(callback !== undefined){

                engine.current.confirmModalCallback = callback;
            }
        },

        closeConfirmModal: execute => {

            setConfirmModal(false);

            if(engine.current.confirmModalCallback !== false){

                if(execute === true){

                    engine.current.confirmModalCallback();
                }

                engine.current.confirmModalCallback = false;
            }
        },

        openForbiddenModal: () => {

            engine.current.openAlertModal(getLang('system').messages.forbidden, () => {

                engine.current.logout();
                engine.current.redirect();
            });
        },

        openNetworkErrorModal: callback => {

            if(callback !== undefined){

                const message = getLang('system').messages.network_error;
                const button =  getLang('system').confirm_modal.try_again;

                engine.current.openConfirmModal(message, callback, button);
            }

            else {

                engine.current.openAlertModal(getLang('system').messages.network_error);
            }
        },

        // Handle login

        login: (accessToken, refreshToken) => {

            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('refresh_token', refreshToken);

            const payload = engine.current.getPayload();

            if(payload.groups === undefined){

                console.error('Groups are not set as payload');
            }

            engine.current.setState({groups: payload.groups});

            return true;
        },

        logout: () => {

            engine.current.setState({groups: []});
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');

            return true;
        },

        getPayload: () => {

            const access_token = localStorage.getItem('access_token');

            if(access_token === null){
                
                return null;
            }

            const parts = access_token.split('.');

            if(parts.length !== 3){

                console.error('Invalid access token');
            }

            const payload = JSON.parse(decodeURIComponent(escape(window.atob(parts[1]))));

            return payload;
        },

        redirect: () => {

            engine.current.setRoute(config.redirect.forbidden(app.path));
        }
    });

    const router = useMemo(

        () => {

            // Parse new routes when app.path changes
            const router = parseRoutes(mainRoutes, app.path);

            // Set new current route
            router.current = router.routes[router.routes.length - 1]; 
    
            // Set new side
            [engine.current.side] = router.current.component.split('/');

            return router;
        },

        [mainRoutes, app.path]
    );

    if(app.status === APP_STATUS_BOOTING){

        return false;
    }

    const themeName = getThemeName() + '@theme';
    const theme = engine.current.imports.get(themeName);

    if(app.status === APP_STATUS_LOADING){

        return (

            <ThemeProvider theme={theme.module}>

                <Loading />

            </ThemeProvider>
        );
    }

    // Init is done after this line!!!

    function getCurrentPath(){

        if(router === false){

            return false;
        }

        return router.routes.map(route => route.path);
    }

    function parseQueryString(){

        const data = {};
        const search = document.location.search;
        
        if(search === ''){

            return data;
        }

        else {

            const [, query] = search.split('?');
            const pairs = query.split('&');

            pairs.forEach(pair => {

                const [key, value] = pair.split('=');

                data[key] = value;
            });
        }

        return data;
    }

    function getLang(type){

        let imported;

        if(type === 'component'){

            imported = engine.current.imports.get(engine.current.lang + '@' + engine.current.side);
        }

        else if(type === 'system'){

            imported = engine.current.imports.get(engine.current.lang + '@lang');
        }

        if(imported === undefined){

            return false;
        }

        return imported.module;
    }

    // Init state for useInit hook
    function setInitState(key, value){

        const init = new Map(engine.current.state.init);
        init.set(key, value);
        engine.current.setState({init: init});
    }

    function getInitState(key){

        return engine.current.state.init.get(key);
    }

    function setInitStatus(query){

        const InitMap = engine.current.init;
        InitMap.set(engine.current.state.path, query);
        engine.current.init = InitMap;
    }

    function getInitStatus(){

        return engine.current.init.get(engine.current.state.path);
    }

    const values = {

        stores: {

            stores: engine.current.state.stores,
            setStores: options => setStores(engine, options)
        },

        route: {

            setRoute: engine.current.setRoute,
            route: {

                path: getCurrentPath(),
                params: router.params,
                query: parseQueryString()
            }
        },

        langify: [getLang('component'), getLang('system'), lang, setLang],

        alertModal: engine.current.openAlertModal,
        confirmModal: engine.current.openConfirmModal,

        loading: {

            state: engine.current.state.loading,

            setLoading: (isLoading, progress) => {

                if(isLoading === false || progress === undefined){

                    progress = null;
                }

                engine.current.setState({

                    loading: {
                    
                        isLoading: isLoading,
                        progress: progress
                    }
                });
            }
        },

        openForbiddenModal: engine.current.openForbiddenModal,
        openNetworkErrorModal: engine.current.openNetworkErrorModal,

        authentication: {

            login: engine.current.login, 
            logout: engine.current.logout,
            getPayload: engine.current.getPayload
        },

        side: engine.current.side,

        sharedState: {

            state: engine.current.state.sharedState,
            setState: state => engine.current.setState({sharedState: merge(engine.current.state.sharedState, state)})
        },

        actions: engine.current.actions,

        // Trigger render to execute actions

        action: {

            setState: uuid => engine.current.setState({action: uuid})
        },

        theme: {

            mode: engine.current.mode,
            setMode: setMode
        },

        init: {

            setInitState: setInitState,
            getInitState: getInitState,
            setInitStatus: setInitStatus,
            getInitStatus: getInitStatus
        },

        install: {

            event: engine.current.state.install,

            trigger: () => {

                engine.current.state.install.prompt();
                engine.current.setState({install: null})
            }
        }
    };

    function Logger(){

        if(process.env.NODE_ENV === 'development' && router !== false){

            if(engine.current.prev.state.status === APP_STATUS_LOADING || engine.current.prev.state.path !== app.path){

                console.log('Route:', router.current.path);
                console.log('Component:', 'ui/src/components/' + router.current.component + '.js');

                const namespaces = [];

                engine.current.state.sharedState.forEach((v, namespace) => {

                    namespaces.push(namespace);
                });

                if(namespaces.length !== 0){

                    console.group('State namespaces:');

                    const sorted = sort(namespaces, 'asc');

                    sorted.forEach(namespace => {

                        console.log(namespace, '(' + typeof engine.current.state.sharedState.get(namespace) + ')');
                    });

                    console.groupEnd();
                }
            }
        }

        return false;
    }

    return (

        <ThemeProvider theme={theme.module}>

            <AppProvider value={values}>

                {engine.current.state.loadingError === false && app.status === APP_STATUS_COMPLETE && engine.current.isForbidden(app.path) === false && router !== false &&

                    <React.Fragment>

                        {Array.from(engine.current.state.modals.entries()).map(([key, component]) => {

                            // Render modals using UUID key set by useModal hook
                            const Modal = React.cloneElement(component, {key: key});

                            return Modal;
                        })}

                        {router.routes.map(route => {

                            const imported = engine.current.imports.get(route.component);
                            const Component = imported.module;

                            // Pass props with useRoute for the next route

                            const props = {};

                            if(engine.current.props !== undefined && engine.current.props.path === route.routePath){

                                props.data = engine.current.props.data;
                            }

                            return <Component key={route.uniquePath} depth={route.depth} {...props} />
                        })}

                    </React.Fragment>
                }

                <Preloading isOpen={app.loading.isLoading} progress={app.loading.progress} />
                {confirmModal !== false && <Confirm value={confirmModal.message} close={engine.current.closeConfirmModal} lang={{ok: confirmModal.ok}} />}
                <Alert value={alertModal} close={engine.current.closeAlertModal} />
                <Logger />

            </AppProvider>

        </ThemeProvider>
    );
}

export {route, group};
