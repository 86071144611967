export default function parseNumber(v){

    if(typeof v !== 'string' && typeof v !== 'number'){

        return 0;
    }

    const number = parseFloat(v.toString().replace(new RegExp(' ', 'g'), '').replace(',', '.'));

    if(isNaN(number)){

        return 0;
    }

    return number;
}
