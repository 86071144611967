const DateTime = {

    format: function(v){

        if(v === undefined){

            console.warn('DateTime is not set');

            return undefined;
        }

        if(v === null){

            return null;
        }

        let [date, time] = v.split(' ');
        let [year, month, day] = date.split('-');
        let finnishFormat = day + '.' + month + '.' + year;

        if(time !== undefined){

            finnishFormat += ' ' + time;
        }

        return finnishFormat;
    }
};

export default DateTime;
