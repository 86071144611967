import {useContext} from 'react';
import {ThemeContext} from 'styled-components';
import AppContext from 'library/AppContext';

export function useTheme(){

    const context = useContext(AppContext);

    return {

        theme: useContext(ThemeContext),
        mode: context.theme.mode,
        setMode: context.theme.setMode
    };
}
