import React from 'react';
import styled from 'styled-components';

const Container = styled.div(props => `

    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.50);
    color: ${props.theme.color.core.secondary};
    z-index: 1000;
`);

const Progress = styled.div(props => `

    margin-top: ${props.theme.margin.medium};
`);

function Preloading(props){

    if(props.isOpen === false){

        return false;
    }

    return (

        <Container>

            <i className="fas fa-cog fa-spin fa-3x" />
            {props.progress !== null && <Progress>{props.progress}</Progress>}

        </Container>
    );
}

export default Preloading;
