import React, {useState} from 'react';
import styled from 'styled-components';

const Input = styled.input(() => `

    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`);

export default function File(props){

    const [state, setState] = useState({name: '', size: 0});

    function handler(e){

        if(e.target.files.length === 0){

            props.onChange({name: props.name, value: undefined});

            setState({
                
                name: '',
                size: 0
            });
        }

        else {

            props.onChange({name: props.name, value: e.target.files[0]});

            setState({
                
                name: e.target.files[0].name,
                size: e.target.files[0].size
            });
        }
    }

    return (

        <React.Fragment>

            <label htmlFor={props.name}>{props.children}</label>
            <Input type="file" name={props.name} id={props.name} onChange={handler} />

        </React.Fragment>
    );
}
