import {useContext} from 'react';
import AppContext from 'library/AppContext';

export function useLang(key, type){

    if(type === undefined){

        type = 'component';
    }

    else if(type !== 'component' && type !== 'app'){

        throw new Error('Type has to be component or app');
    }

    const context = useContext(AppContext);
    const [componentTranslations, appTranslations, lang, setLang] = context.langify;
    const translations = type === 'component' ? componentTranslations[key] : appTranslations[key];

    if(translations === undefined){

        console.error('Translation key not found:', key);

        return [{}, lang, setLang];
    }

    return {

        translations: translations,
        lang: lang,
        setLang: setLang
    };
}
