import {useRef, useState} from 'react';

export function useForm(init){

    const [, setState] = useState(0);

    if(typeof init === 'object' && init instanceof Map === false){

        const map  = new Map();

        for(let k in init){

            map.set(k, init[k]); 
        }

        init = map;
    }

    const ref = useRef({

        data: init || new Map(),

        set: (input, update) => {

            // Remove field from data when value is undefined

            if(input.value === undefined){

                if(ref.current.data.get(input.name) !== undefined){

                    ref.current.data.delete(input.name);
                }
            }

            // Set data as null when value is empty string

            else if(input.value === ''){
                
                ref.current.data.set(input.name, null);
            }

            else {

                ref.current.data.set(input.name, input.value);
            }

            update = update === undefined ? true : update;

            if(update === true){
            
                setState((prev) => prev + 1);
            }
        },

        get: (key, defaultValue) => {

            if(key === undefined){

                return ref.current.data;
            }

            // If key is array without index like example[] return Map of matched fields

            const regex = new RegExp(/\[\]/);

            if(key.match(regex) !== null){

                const [name] = key.split('[]')
                const collection = new Map();

                ref.current.data.forEach((v, k) => {

                    const regex = RegExp(name + '[[a-zA-Z0-9_-]+]');

                    if(k.match(regex) !== null){

                        const value = ref.current.data.get(k);

                        if(value !== null){

                            collection.set(k, value);
                        }
                    }
                });

                return collection;
            }

            const value = ref.current.data.get(key);

            // If value is not defined return default value

            if(value === undefined){

                // If default value it not set return NULL as default value

                if(defaultValue === undefined){

                    ref.current.set({name: key, value: null}, false);

                    return null;
                }

                // If default value is set refurn defined default value

                ref.current.set({name: key, value: defaultValue}, false);

                return defaultValue;
            }

            // If value is defined return value

            return value;
        },

        clear: () => {

            ref.current.data.clear();

            setState((prev) => prev + 1);
        }
    });

    return {

        getForm: ref.current.get,
        setForm: ref.current.set,
        clearForm: ref.current.clear
    };
}
