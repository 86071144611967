import React from 'react';
import styled from 'styled-components';

const Container = styled.div(props => `

    display: flex;
    align-items: center;
    margin-bottom: ${props.theme.margin.medium};
`);

const Box = styled.div(props => `

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ${props.theme.width.medium};
    width: ${props.theme.width.medium};
    height: ${props.theme.height.medium};
    min-height: ${props.theme.height.medium};
    border-radius: ${props.theme.borderRadius.small};
    background: ${props.theme.backgroundColor.core.tertiary};
    border: ${props.theme.borderWidth.small} solid ${props.theme.borderColor.core.primary};
    cursor: pointer;
`);

const Label = styled.div(props => `

    padding-left: ${props.theme.padding.medium};
    ${props.$error !== false ? `color: ${props.theme.color.negative.primary}` : ``}
`);

function Checkbox(props){

    function toggle(){

        // Change to opposite value on toggle

        props.onChange({

            name: props.name,
            value: getOppositeValue()
        });
    }

    function getOppositeValue(){

        return isChecked() === false ? props.value : null;
    }

    function isChecked(){

        if(props.checked === 0 || props.checked === false || props.checked === null){

            return false;
        }

        return true;
    }

    return (
        
        <Container style={props.style}>

            <Box onClick={toggle}>{isChecked() === true && <i className="fas fa-check" />}</Box>
            {props.label !== undefined && <Label $error={props.error}>{props.label}</Label>}

        </Container>
    );
}

Checkbox.defaultProps = {

    value: null,
    error: false
}

export default Checkbox;
