import React, {useLayoutEffect, useRef, useState} from 'react';
import styled from 'styled-components';

const Parent = styled.div(props => `

    position: relative;
    height: ${props.$height};
`);

const Child = styled.div(props => `

    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: ${props.$height};
`);

const Content = styled.div`
 
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export default function AspectRatio(props){

    useLayoutEffect(init, []);
    
    const contentRef = useRef(null);
    const [state, setState] = useState({height: 'auto'});

    function init(){

        setHeight();

        window.addEventListener('resize', onResize);

        return () => {

            window.removeEventListener("resize", onResize);
        }
    }

    function setHeight(){

        const height = contentRef.current.clientHeight + 'px';

        setState({height: height});
    }

    function onResize(){

        setHeight();
    }

    return (

        <Parent $height={state.height} style={props.style}>

            <Child $height={props.height}>

                <Content ref={contentRef}>{props.children}</Content>

            </Child>

        </Parent>

    );
}

AspectRatio.defaultProps = {

    height: '66%'
}
