import React from 'react';
import styled from 'styled-components';

const Container = styled.div(props => `

    margin-bottom: ${props.theme.margin.medium};
`);

const Label = styled.div(props => `

    padding-bottom: ${props.theme.padding.medium};
    ${props.$error !== false && `color: ${props.theme.color.negative.primary}`}
`);

const SelectInput = styled.select(props => `

    width: 100%;
    height: ${props.theme.height.medium};
    border-radius: ${props.theme.borderRadius.small};
    font-size: ${props.theme.fontSize.medium};
    padding: 0 ${props.theme.padding.small} 0 ${props.theme.padding.small};
    border: ${props.theme.borderWidth.small} solid ${props.theme.borderColor.core.primary};
    -webkit-appearance: none;
    background: ${props.theme.backgroundColor.core.tertiary};
    cursor: pointer;

    &:focus {

        outline: 0;
        box-shadow: 0 0 0.3em ${props.borderColor} !important;
    }
`);

function Select(props){

    function change(e){

        // Return NULL if empty string
        const value = e.target.value === '' ? null : e.target.value;

        const input = {

            name: props.name,
            value: value
        };

        return props.onChange(input);
    }

    return (

        <Container style={props.style}>

            {props.label && <Label $error={props.error}>{props.mandatory === true && '* '}{props.label}:</Label>}

            <SelectInput onChange={change} defaultValue={props.value}>

                {props.options.map((v, i) => <option key={i} value={v.value === null ? '' : v.value}>{v.text}</option>)} 

            </SelectInput>

        </Container>
    );
}

Select.defaultProps = {

    error: false,
    styled: {}
}

export default Select;
