import {useContext} from 'react';
import AppContext from 'library/AppContext';

export function useConfirm(){

    const context = useContext(AppContext);

    function setConfirm(text, callback){

        context.confirmModal(text, callback);
    }

    return {setConfirm: setConfirm};
}
