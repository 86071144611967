import config from 'config';

async function request(options){

    if(options.method === undefined){

        console.warn('Request method is not set');
    }

    if(options.endpoint === undefined && options.url === undefined){

        console.warn('Request endpoint is not set');
    }

    function get_host(){

        if(options.host !== undefined){

            return options.host;
        }

        return process.env.HOST
    }

    function get_url(){

        if(options.url !== undefined){

            return options.url;
        }

        const host = get_host();
        const url = host + options.endpoint;

        return url;
    }

    function modify(data){

        for(let key in options.modifiers){

            const callback = options.modifiers[key];
            data[key] = callback(data[key]);
        }

        return data;
    }

    let renew_is_not_tried = true; // Try renew access token only once

    async function renew_access_token(){

        renew_is_not_tried = false;

        const host = get_host();
        const url = host + config.endpoints.refreshAccessToken.endpoint;
        const method = config.endpoints.refreshAccessToken.method;

        const params = {

            method: method,
            headers: {}
        }

        let refresh_token = localStorage.getItem('refresh_token');

        if(refresh_token === null){

            return false;
        }

        params.headers['Authorization'] = `Bearer ${refresh_token}`;

        let result;

        try {

            const response = await fetch(url, params);

            result = await response.json();
        }

        catch(error){

            return false;
        }

        if(typeof result !== 'object'){

            return false;
        }

        if(typeof result.data !== 'object'){

            return false;
        }

        if(result.data.access_token === undefined || result.data.refresh_token === undefined){

            return false;
        }

        localStorage.setItem('access_token', result.data.access_token);
        localStorage.setItem('refresh_token', result.data.refresh_token);

        return true;
    }

    function set_headers(){

        const headers = {};

        if(options.url === undefined){

            // Add authorization header

            let access_token = localStorage.getItem('access_token');

            if(access_token !== null){

                headers['Authorization'] = `Bearer ${access_token}`;
            }
        }

        // Set custom headers via options

        if(options.headers !== undefined){

            for(let header in options.headers){

                headers[header] = options.headers[header];
            }
        }

        return headers;
    }

    function set_body(){

        let data;

        if(options.method.toUpperCase() !== 'GET'){

            if(typeof options.data === 'object'){

                if(options.data instanceof Map){

                    data = JSON.stringify(Object.fromEntries(options.data));
                }

                else if(options.data instanceof File){

                    data = options.data; // Not visible in Developer Tools as payload
                }

                else {

                    data = JSON.stringify(options.data);
                }
            }
        }

        return data;
    }

    async function make(){

        const url = get_url();

        const params = {

            method: options.method,
            headers: set_headers(),
            body: set_body()
        };

        let result = false;

        try {

            const response = await fetch(url, params);

            if(response.status === 403){

                // Try renew access token

                if(renew_is_not_tried === true && await renew_access_token() === true){

                    // Make request again with new access token

                    return make();
                }

                else {

                    // Access token is expired and cannot be renewed

                    return Promise.reject({error: response.statusText, forbidden: true});
                }
            }

            const text = await response.text();

            let body;

            try {

                body = JSON.parse(text);

                // Execute modifier callbacks
                if(body.data !== undefined && options.modifiers !== undefined){

                    // Modify array of objects
                    if(Array.isArray(body.data)){

                        body.data.forEach((v, k) => {

                            body.data[k] = modify(v);
                        });
                    }

                    // Modify object
                    else if(typeof body.data === 'object'){

                        body.data = modify(body.data);
                    }
                }
            }

            catch(error){

                body = text;
            }

            const data = {

                body: body,

                status: {

                    code: response.status,
                    text: response.statusText
                },

                success: response.status === 200 ? true : false,
                error: response.status !== 200 ? true : false
            };

            return data;
        }

        // Fetch will throw error only if there is no connection

        catch(error){

            return Promise.reject({error: error.message, network: true, timeout: true});
        }
    }

    return make();
}

export default request;
