import React from 'react';
import styled from 'styled-components';

const Container = styled.div(props => `

    margin-bottom: ${props.theme.margin.medium};
`);

const Label = styled.div(props => `

    padding-bottom: ${props.theme.padding.medium};
    ${props.$error !== false && `color: ${props.theme.color.negative.primary}`}
`);

const Field = styled.textarea(props => `

    width: 100%;
    height: 10em;
    color: ${props.theme.color.core.primary};
    padding: ${props.theme.padding.small};
    border: 1px solid ${props.theme.borderColor.core.primary};
    border-radius: ${props.theme.borderRadius.small};
    outline: 0;
    resize: none;
`);

function Textarea(props){

    function change(e){

        const input = {

            name: props.name,
            value: e.target.value
        };

        return props.onChange(input);
    }

    const value = props.value === null ? '' : props.value;

    return(

        <Container style={props.style}>

            <Label $error={props.error}>{props.label}:</Label>
            <Field name={props.name} onChange={change} value={value} />
        
        </Container>
    );
}

Textarea.defaultProps = {

    styled: {},
    error: false
};

export default Textarea;
