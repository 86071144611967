import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div(props => `

    margin-bottom: ${props.theme.margin.medium};
    width: 100%;
`);

const Label = styled.div(props => `

    padding-bottom: ${props.theme.padding.medium};
    color: ${props.$error !== false ? props.theme.color.negative.primary : `inherit`};
    white-space: nowrap;
`);

const Field = styled.input(props => `

    width: 100%;
    background: ${props.theme.backgroundColor.core.tertiary};
    color: ${props.theme.color.core.primary};
    height: ${props.theme.height.medium};
    padding: 0 ${props.theme.padding.small} 0 ${props.theme.padding.small};
    border: 1px solid ${props.theme.borderColor.core.primary};
    border-radius: ${props.theme.borderRadius.small};
    outline: 0;
`);

function Input(props){

    function change(e){

        const input = {

            name: props.name,
            value: e.target.value
        };

        return props.onChange(input);
    }

    const value = props.value === null ? '' : props.value;
    const placeholder = props.placeholder !== undefined ? props.placeholder : '';

    return (

        <Wrapper style={props.style}>

            <Label $error={props.error}>{props.mandatory === true && '* '}{props.label}:</Label>
            <Field name={props.name} value={value} onChange={change} type={props.type} placeholder={placeholder} />

        </Wrapper>
    );
}

Input.defaultProps = {

    error: false,
    type: 'text'
}

export default Input;
