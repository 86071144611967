export default function setStores(engine, options){

    if(options.action === undefined){

        throw new Error('Action is not set');
    }

    if(options.store === undefined){

        throw new Error('Store is not set');
    }

    if(options.data === undefined){

        throw new Error('Data is not set');
    }

    const stores = engine.current.state.stores;

    if(stores[options.store] === undefined){

        stores[options.store] = new Map();
    }

    const store = stores[options.store];

    switch (options.action) {

        case 'add':

            if(store.get(options.data.uuid) !== undefined){

                throw new Error('Add failed because item key already exists');
            }

            store.set(options.data.uuid, options.data);

            break;

        case 'update':

            if(store.get(options.data.uuid) === undefined){

                throw new Error('Update failed because item key does not exist');
            }

            store.set(options.data.uuid, options.data);

            break;

        case 'delete':

            if(store.get(options.data.uuid) === undefined){

                throw new Error('Delete failed because item key does not exist');
            }

            store.delete(options.data.uuid);

            break;
    }

    engine.current.setState({stores: stores});
}
