import React from 'react';
import sort from 'fast-sort';
import styled from 'styled-components';
import {parseNumber} from 'library/helpers';

const Wrapper = styled.div(props => `

    margin-bottom: ${props.theme.margin.medium};
`);

const Container = styled.div(props => `

    display: inline;
    padding-right: ${props.theme.padding.small};
`);

function Order(props){

    if(props.type === 'asc'){

        return <Container><i className="fas fa-sort-amount-down" /></Container>;
    }

    else if(props.type === 'desc'){

        return <Container><i className="fas fa-sort-amount-up" /></Container>;
    }

    return false;
}

const Table = styled.table(props => `

    width: 100%;
    border-collapse: separate;

    @media (max-width: ${props.theme.minWidth.tablet}){

        & thead {

            display: none;
        }

        &, & tbody, & tr, & td {

            display: block;
        }
    }
`);

const Titles = styled.tr(props => `

    & > td {

        border-top: 1px solid ${props.theme.borderColor.main.primary};
    }

    & > td:first-of-type {

        border-left: 1px solid ${props.theme.borderColor.main.primary};
    }

    & > td:last-of-type {

        border-right: 1px solid ${props.theme.borderColor.main.primary};
    }

    & > td:first-of-type {

        border-radius: ${props.theme.borderRadius.small} 0 0 0;
    }

    & > td:last-of-type {

        border-radius: 0 ${props.theme.borderRadius.small} 0 0;
    }
`);

const Title = styled.td(props => `

    padding: ${props.theme.padding.medium};
    background: linear-gradient(${props.theme.backgroundColor.main.primary}, ${props.theme.backgroundColor.main.secondary});
    color: ${props.theme.color.main.primary};
`);

const Row = styled.tr(props => `

    & > td {

        background-color: ${props.theme.backgroundColor.core.tertiary};
        color: ${props.theme.color.core.tertiary};
    }

    & > td:first-of-type {

        border-top: 1px solid ${props.theme.borderColor.core.primary};
    }

    & > td:last-of-type {

        margin-bottom: ${props.theme.margin.medium};
    }

    & > td {

        border-left: 1px solid ${props.theme.borderColor.core.primary};
        border-right: 1px solid ${props.theme.borderColor.core.primary};
        border-bottom: 1px solid ${props.theme.borderColor.core.primary};
    }

    @media (min-width: ${props.theme.minWidth.tablet}){

        & > td:first-of-type {

            border-top: 0;
        }

        & > td:last-of-type {

            margin-bottom: 0;
        }

        & td {

            border: 0;
        }

        & > td {

            border-bottom: 1px solid ${props.theme.borderColor.core.primary};
        }

        & > td:first-of-type {

            border-left: 1px solid ${props.theme.borderColor.core.primary};
        }

        & > td:last-of-type {

            border-right: 1px solid ${props.theme.borderColor.core.primary};
        }

        &:last-of-type > td:first-of-type {

            ${props.$pagination === false && `border-radius: 0 0 0 ${props.theme.borderRadius.small};`}
        }

        &:last-of-type > td:last-of-type {

            ${props.$pagination === false && `border-radius: 0 0 ${props.theme.borderRadius.small} 0;`}
        }

        ${props.$pagination === true && `

            &:last-of-type > td {

                border-bottom: 0;
            }
        `}
    }
`);

const Column = styled.td(props => `

    padding: ${props.theme.padding.medium};
    vertical-align: middle;

    @media (max-width: ${props.theme.minWidth.tablet}){

        text-align: right;

        ${props.title !== undefined && props.title !== null && `

            &:before {

                float: left;
                content: '${props.title !== '' ? props.title + ':': ''}';
            }
        `}
    }
`);

const Pagination = styled.div(props => `
    
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(${props.theme.backgroundColor.main.primary}, ${props.theme.backgroundColor.main.secondary});
    color: ${props.theme.color.main.primary};
    padding: ${props.theme.padding.medium};
    border-radius: 0 0 ${props.theme.borderRadius.small} ${props.theme.borderRadius.small};
`);

const ChangePage = styled.div`

    ${props => `

        display: flex;
        align-items: center;
        justify-content: center;
        width: ${props.theme.width.medium};
        height: ${props.theme.height.medium};
        background: linear-gradient(${props.theme.backgroundColor.sub.primary}, ${props.theme.backgroundColor.sub.secondary});
        border: 1px solid ${props.theme.borderColor.sub.primary};
        border-radius: ${props.theme.borderRadius.small};
        cursor: pointer;

        &:hover {

            background: linear-gradient(${props.theme.backgroundColor.positive.primary}, ${props.theme.backgroundColor.positive.secondary});
            border: 1px solid ${props.theme.borderColor.positive.primary};
        }
    `}
`;

const Sort = styled.div`

    cursor: pointer;
`;

const CurrentPage = styled.span`

    ${props => `
   
        margin-left: ${props.theme.margin.medium};
        margin-right: ${props.theme.margin.medium};
    `}
`;

class LightTable extends React.Component {

    constructor(props){

        super(props);

        const first = Object.keys(this.props.headers)[0];
        const header = this.props.headers[first];

        const defaultSort = this.props.defaultSort !== undefined ? this.props.defaultSort : header === false ? false : first;
        const defaultOrder = this.props.defaultOrder !== undefined ? this.props.defaultOrder : 'asc';

        this.state = {

            page: 0,
            sort: defaultSort,
            order: defaultOrder
        }
    }

    resetBenchmark = () => {

        this.resetTime = Date.now();
    }

    benchmark = () => {

        let difference = Date.now() - this.resetTime;

        console.log('Benchmark: ' + difference + ' ms, Items: ' + this.props.data.length);
    }

    sortBy = key => {

        const order = this.state.sort !== key ? 'asc' : this.state.order === 'asc' ? 'desc' : 'asc';

        this.setState({

            sort: key,
            order: order
        });
    }

    sort = (data, field, order) => {

        const options = {

            comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare
        };

        if(order === 'asc'){

            options.asc = v => v[field];
        }

        else if(order === 'desc'){

            options.desc = v => v[field];
        }

        return sort(data).by(options);
    }

    pagination = data => {

        const pages = parseNumber(this.props.pagination);

        if(pages === 0){

            return data;
        }

        const from = this.state.page * pages;
        const to = from + pages;

        data = data.filter((v, k) => {

            if(k >= from & k < to){

                return true;
            }

            return false;
        });

        return data;
    }

    // Arrange data in same order than headers.

    arrange = (values) => {

        values = new Map(values);

        const arranged = [];

        for(let key in this.props.headers){

            arranged.push([key, values.get(key)]);
        }

        return arranged;
    }

    changePage = page => {

        this.setState({

            page: this.state.page + page
        });
    }

    get total(){

        if(this.props.pagination === false){

            return 1;
        }

        return Math.ceil(this.props.data.length / this.props.pagination);
    }

    modify = (key, row) => {

        const modifiers = this.props.modifiers || {};

        if(modifiers[key] !== undefined){

            return modifiers[key](key, row);
        }

        return row[key];
    }

    hasPagination = () => {

        if(this.props.pagination === false || this.props.data.length <= parseInt(this.props.pagination)){

            return false;
        }

        return true;
    }

    render(){

        if(this.props.data === undefined || this.props.data.length === 0){

            return false;
        }

        this.resetBenchmark();

        let data = this.props.data;

        data = this.sort(data, this.state.sort, this.state.order);
        data = this.pagination(data);

        let headers = Array.from(new Map(Object.entries(this.props.headers)));
        let keys = [];

        return (

            <Wrapper style={this.props.style}>

                <Table $breakpoint={this.props.breakpoint} $pagination={this.props.pagination} $pages={this.total}>

                    <thead>

                        <Titles>

                            {headers.map((header) => {

                                keys.push(header[0]);

                                return (

                                    <Title key={header[0]}>

                                        <Sort onClick={() => this.sortBy(header[0])}>

                                            {this.state.sort === header[0] && <Order type={this.state.order} />}
                                            {header[1] !== null && header[1] + ':'}

                                        </Sort>

                                    </Title>
                                );
                            })}

                        </Titles>

                    </thead>

                    <tbody>

                        {data.map((row, i) => {

                            let values = Array.from(new Map(Object.entries(row))).filter(v => {

                                // Filter out fields that are not included in headers.

                                if(keys.indexOf(v[0]) === -1){

                                    return false;
                                }

                                return true;
                            });

                            // Use id as key if it is set.


                            let key = i;

                            if(row.id !== undefined){

                                key = row.id;
                            }

                            else if(row.uuid !== undefined){

                                key = row.uuid;
                            }

                            return (

                                <Row key={key} $breakpoint={this.props.breakpoint} $pagination={this.hasPagination()}>

                                    {this.arrange(values).map((v) => {

                                        const title = this.props.headers[v[0]];

                                        return <Column key={v[0]} title={title !== false ? title : ''} $breakpoint={this.props.breakpoint}>{this.modify(v[0], row)}</Column>;
                                    })}
                               
                                </Row>
                            );
                        })}

                    </tbody>

                    {this.props.benchmark === true && [0].map(() => this.benchmark())}

                </Table>

                {this.hasPagination() === true &&

                    <Pagination>

                        {this.state.page > 0 && <ChangePage onClick={() => this.changePage(-1)}><i className="fas fa-caret-left" /></ChangePage>}
                        <CurrentPage>{this.state.page + 1} / {this.total}</CurrentPage>
                        {this.state.page + 1 < this.total && <ChangePage onClick={() => this.changePage(1)}><i className="fas fa-caret-right" /></ChangePage>}

                    </Pagination>
                }

            </Wrapper>
        );
    }
}

LightTable.defaultProps = {

    benchmark: false,
    pagination: false,
    breakpoint: '1000px'
}

export default LightTable;
