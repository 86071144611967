import React from 'react';
import {Select} from 'library/components';
import {useForm, useLang} from 'library/hooks';
import styled from 'styled-components';

const Label = styled.div(props => `

    padding-bottom: ${props.theme.padding.medium};
`);

const Inputs = styled.div(props => `

    padding: ${props.theme.padding.medium};
    background: ${props.theme.backgroundColor.core.secondary};
    border: 1px solid ${props.theme.borderColor.core.primary};
    border-radius: ${props.theme.borderRadius.small};
    margin-bottom: ${props.theme.margin.medium};
`);

const InputDate = styled.div(props => `

    display: flex;

    & > div:not(:last-of-type) {

        margin-right: ${props.theme.margin.medium};
    }
`);

const InputTime = styled.div(props => `

    display: flex;

    & > div:not(:last-of-type) {

        margin-right: ${props.theme.margin.medium};
    }
`);

export default function Calendar(props){

    const {translations} = useLang('calendar', 'app');
    const {getForm, setForm} = useForm(getDefaultDateTime());

    function getDefaultDateTime(){

        const DateTime = props.value !== null ? new Date(props.value) : new Date();

        const datetime = {

            year: DateTime.getFullYear(),
            month: DateTime.getMonth() + 1,
            day: DateTime.getDate(),
            hour: DateTime.getHours(),
            minute: DateTime.getMinutes()
        }

        return datetime;
    }

    function getOptions(from, to){

        const options = [];

        for(let i = from; i <= to; i++){

            options.push({value: i, text: i});
        }

        return options;
    }

    // Day
    function getDays(){
    
        return getOptions(1, 31);
    }

    // Month
    function getMonths(){

        return getOptions(1, 12);
    }

    // Year
    function getYears(){

        return getOptions(2024, 2026);
    }

    // Hour
    function getHours(){

        return getOptions(1, 23);
    }

    // Minute
    function getMinutes(){

        if(props.minutes === 'all'){

            return getOptions(0, 59);
        }

        const options = [];

        options.push({value: 0, text: '00'});
        options.push({value: 15, text: 15});
        options.push({value: 30, text: 30});
        options.push({value: 45, text: 45});

        return options;       
    }

    function setDateTime(input){

        setForm(input);

        // Database DATETIME format
        const value = getForm('year') +
        '-' +
        getForm('month') +
        '-' +
        getForm('day') +
        ' ' +
        getForm('hour') +
        ':' +
        getForm('minute');

        console.log('Value:', value);

        props.onChange({name: props.name, value: value});
    }

    return (

        <React.Fragment>

            <Label>{props.label}:</Label>

            <Inputs>

                <InputDate>

                    <Select label={translations.day} name="day" options={getDays()} value={getForm('day')} onChange={setDateTime} style={{width: '5em'}} />
                    <Select label={translations.month} name="month" options={getMonths()} value={getForm('month')} onChange={setDateTime} style={{width: '5em'}} />
                    <Select label={translations.year} name="year" options={getYears()} value={getForm('year')} onChange={setDateTime} style={{width: '5em'}} />

                </InputDate>

                <InputTime>

                    <Select label={translations.hour} name="hour" options={getHours()} value={getForm('hour')} onChange={setDateTime} style={{width: '5em', marginBottom: 0}} />
                    <Select label={translations.minute} name="minute" options={getMinutes()} value={getForm('minute')} onChange={setDateTime} style={{width: '5em', marginBottom: 0}} />

                </InputTime>

            </Inputs>

        </React.Fragment>
    );
}
