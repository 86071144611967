import {useContext} from 'react';
import AppContext from 'library/AppContext';

export function useAlert(){

    const context = useContext(AppContext);

    function setAlert(text, callback){

        context.alertModal(text, callback);
    }

    return {setAlert: setAlert};
}
