import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import {Button} from 'library/components';
import {useRoute} from 'library/hooks';

const Align = styled.div(props => `

    display: flex;
    justify-content: ${props.$align};
`);

const CloseMenu = styled.div`

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
`;

const Wrapper = styled.div`

    position: relative;
`;

const OpenMenu = styled.div(props => `

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: ${props.theme.padding.small};
    padding-right: ${props.theme.padding.small};
    width: ${props.theme.height.medium};
    height: ${props.theme.height.medium};
    border-radius: ${props.theme.borderRadius.small}; 
    color: ${props.theme.color.main.primary};
    background: ${props.theme.backgroundColor.sub.primary};
    border: 1px solid ${props.theme.backgroundColor.sub.secondary};
    cursor: pointer;
    font-weight: bold;

    @media(hover: hover) and (pointer: fine){

        &:hover {

            background: linear-gradient(${props.theme.backgroundColor.positive.primary}, ${props.theme.backgroundColor.positive.secondary});
            border: 1px solid ${props.theme.backgroundColor.positive.primary};
        }
    }
`);

const Menu = styled.div(props => `

    position: absolute;
    right: 0;
    padding: ${props.theme.padding.medium};
    border-radius: ${props.theme.borderRadius.small};
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 1000;

    & > Button:last-of-type {

        margin: 0; 
    }
`);

export default function QuickMenu(props){

    const [calculate, setCalculate] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const {setRoute} = useRoute();

    const buttonRef = useRef();
    const menuRef = useRef();
    const totalHeightRef = useRef(null);

    useEffect(() => {

        if(calculate === true){

            totalHeightRef.current = document.body.scrollHeight;
            setIsOpen(true);
        }

    }, [calculate]);

    useEffect(() => {

        if(isOpen === true){

            const buttonRect = buttonRef.current.getBoundingClientRect();
            const menuHeight = menuRef.current.offsetHeight;
            const scrollTop = window.scrollY || document.body.scrollTop;
            const totalHeight = totalHeightRef.current;
            const minHeight = buttonRect.top + menuHeight + scrollTop;

            if(totalHeight > minHeight){
            
                menuRef.current.style.top = '0';
                menuRef.current.style.bottom = 'auto';
            }

            else {

                menuRef.current.style.bottom = '0';
                menuRef.current.style.top = 'auto';
            }
        }

    }, [isOpen]);

    function open(){

        setCalculate(true);
    }

    function close(){

        setIsOpen(false);
        setCalculate(false);
    }

    function getAlign(){

        if(props.align === 'left'){

            return 'flex-start';
        }

        else if(props.align === 'center'){

            return 'center';
        }

        else if(props.align === 'right'){

            return 'flex-end';
        }

        throw new Error('Invalid align property');
    }

    function action(option){

        close();

        if(option.url !== undefined){

            setRoute(option.url);
        }

        else if(option.callback !== undefined){

            option.callback();
        }
    }

    return (

        <Align $align={getAlign()}>

            {isOpen === true && <CloseMenu onClick={close} />}

            <Wrapper>

                {isOpen === true &&

                    <Menu ref={menuRef}>

                        {props.options.map((option, index) =>

                            <Button key={index} onClick={() => action(option)}>{option.title}</Button>
                        )}

                    </Menu>
                }

                <OpenMenu onClick={open} ref={buttonRef}>&#8942;</OpenMenu>

            </Wrapper>

        </Align>
    );
}

QuickMenu.defaultProps = {

    align: 'center'
};
