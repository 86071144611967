import {useContext} from 'react';
import AppContext from 'library/AppContext';

export function useRoute(){

    const context = useContext(AppContext);

    return {

        setRoute: context.route.setRoute,
        route: context.route.route
    };
}
