import {round} from 'library/helpers';

export default function numberFormat(v, precision, delimiter){

    if(precision !== undefined){

        v = round(v, precision);
    }

    let [integer, decimals] = v.toString().replace(new RegExp(' ', 'g'), '').replace(',', '.').split('.');
    let formatted = integer;

    if(integer.length === 7){

        let first = integer.substr(0, 1);
        let second = integer.substr(1, 3);
        let third = integer.substr(3, 3);

        formatted = first + ' ' + second + ' ' + third;
    }

    if(integer.length === 6){

        let first = integer.substr(0, 3);
        let second = integer.substr(3, 3);

        formatted = first + ' ' + second;
    }

    else if(integer.length === 5){

        let first = integer.substr(0, 2);
        let second = integer.substr(2, 3);

        formatted = first + ' ' + second;
    }

    else if(integer.length === 4){

        let first = integer.substr(0, 1);
        let second = integer.substr(1, 3);

        formatted = first + ' ' + second;
    }

    if(decimals !== undefined){

        formatted = formatted + '.' + decimals;
    }

    // Add trailing zeroes

    if(precision !== undefined){

        const splitted = formatted.split('.');

        let loops;

        if(splitted.length === 1){

            formatted += '.';
            loops = precision;
        }

        else if(splitted.length === 2){

            loops = precision - splitted[1].length;
        }

        for(let i = 0; i < loops; i++){

            formatted += '0';
        }
    }

    // Change delimiter

    if(delimiter !== undefined){

        formatted = formatted.replace('.', delimiter);
    }

    return formatted;
}
