import {useContext, useRef} from 'react';
import AppContext from 'library/AppContext';
import {uuid4} from 'library/helpers';

export function useModal(){

    const context = useContext(AppContext);
    const ref = useRef(null);

    function openModal(Component){

        ref.current = uuid4();
        context.actions.push({type: 'openModal', uuid: ref.current, component: Component});
        context.action.setState(ref.current);
    }

    function closeModal(){

        context.actions.push({type: 'closeModal', uuid: ref.current});
        context.action.setState(ref.current);
    }

    return {

        openModal: openModal,
        closeModal: closeModal
    };
}
