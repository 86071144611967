class ServiceWorker {

    constructor(files, version){

        this.files = files;
        this.cache = version;
    }

    static register = () => {

        if('serviceWorker' in navigator){

            navigator.serviceWorker.register('/service-worker.js').then(

                registration => {

                    console.log('Service Worker registered');

                    registration.addEventListener('updatefound', () => {

                        console.log('Service worker updated');
                    });
                },

                error => {
            
                    console.log('Service Worker not registered:', error);
                }
            );
        }
    }

    listenInstall = () => {

        self.addEventListener("install", e => {

            // Use updated server worker immediately without waiting for a new tab or window

            self.skipWaiting();

            e.waitUntil(caches.open(this.cache).then(cache => {

                // Add files to cache
                
                cache.addAll(this.files);

                return cache;
            }));
        });
    }

    listenActivate = () => {

        self.addEventListener('activate', e => {

            // Use service worker immediately without waiting for next page load

            e.waitUntil(self.clients.claim());
        });
    }

    listenFetch = () => {

        self.addEventListener('fetch', e => {

            e.respondWith(this.loadFromCache(e));
        });
    }

    loadFromCache = async e => {

        const cache = await caches.open(this.cache);
        const cached = await cache.match(e.request);

        if(cached === undefined){

            return fetch(e.request);
        }

        return cached;
    }
}

export default ServiceWorker;
