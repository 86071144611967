import {useContext} from 'react';
import AppContext from 'library/AppContext';

export function useAuth(){

    const context = useContext(AppContext);

    function setTokens(access_token, refresh_token){

        return context.authentication.login(access_token, refresh_token);
    }

    function unsetTokens(){

        return context.authentication.logout();
    }

    function getPayload(){

        return context.authentication.getPayload();
    }

    function isMember(group){
    
        if(payload === null){
            
            return false;
        }

        if(payload.groups === undefined){

            return false;
        }

        if(payload.groups.indexOf(group) === -1){

            return false;
        }

        return true;
    }

    const payload = context.authentication.getPayload();

    return {

        payload: payload,
        isMember: isMember,
        setTokens: setTokens,
        unsetTokens: unsetTokens
    };
}
