export default function download(url, filename){

    if(filename === undefined){

        filename = '';
    }

    const link = document.createElement('a');

    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
}
