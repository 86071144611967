import {isNumeric, withoutRef} from 'library/helpers';

function parseRoutes(routes, path){

    // Assign params to matching route

    function matchRoute(route, path){

        const fragments = [];
        const routePath = path;

        path = path.split('/');

        route.path.split('/').forEach(fragment => {

            // If fragment begins with colon the fragment is parameter.

            if(fragment.substr(0, 1) === ':'){

                const param = fragment.substr(1, fragment.length -1);

                fragments.push ({
                
                    match: param,
                    param: true
                });
            }

            else {

                fragments.push ({
                
                    match: fragment,
                    param: false
                });
            }
        });

        // If path has same amount of fragments try to match it.

        if(path.length === fragments.length){

            let match = true;
            const params = {};

            fragments.forEach((fragment, k) => {

                // If fragment is not parameter it has to match with counterpart of the path.

                if(fragment.param === false && path[k] !== fragments[k].match){

                    match = false;
                }

                // If fragment is parameter save counterpart of the path as parameter.

                if(fragment.param === true){

                    const param = isNumeric(path[k]) === true ? parseFloat(path[k]) : path[k];

                    params[fragment.match] = param;
                }
            });

            if(match === true){

                route.params = params;
                route.routePath = routePath;

                return route;
            }
        }

        return false;
    }

    const tree = [];
    const matches = [];

    let depth = -1;

    function iterate(routes){

        depth++;

        routes.forEach(routeWithRef => {

            const route = withoutRef(routeWithRef);
            const match = matchRoute(route, path);

            if(match !== false){

                tree.forEach(route => {

                    matches.push(route);
                });

                match.depth = depth;
                matches.push(match);
            }

            else if(route.subroutes.length !== 0){

                route.depth = depth;
                tree.push(route);
                iterate(route.subroutes);
            }
        });

        tree.pop();

        depth--;
    }

    iterate(routes);

    if(matches.length === 0){

        return false;
    }

    const params = matches[matches.length - 1].params;

    // Used as key for the route component
    function getRouteUniquePath(path){

        let uniquePath = path;

        Object.keys(params).forEach(key => {

            const regex = new RegExp(`:${key}`, 'g');
            uniquePath = uniquePath.replace(regex, params[key]);
        });
        
        return uniquePath;
    }

    matches.forEach(match => {
        
        // Set unique path
        match.uniquePath = getRouteUniquePath(match.path);
    });

    return {

        routes: matches,
        params: params
    }
}

export default parseRoutes;
