import {useContext} from 'react';
import AppContext from 'library/AppContext';

export function useInstall(){

    const context = useContext(AppContext);
    const ready = context.install.event === null ? false : true;

    function install(){

       if(ready === true){

            context.install.trigger();
       }

       console.warn('Install prompt is not ready');
    }

    return {

        installable: ready,
        install: install
    };
}
