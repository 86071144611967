import fastSort from 'fast-sort';

export default function sort(array, order, property){

    if(property === undefined){

        if(order === 'desc'){

            return fastSort(array).desc();
        }

        return fastSort(array).asc();
    }

    else {

        if(order === 'desc'){

            return fastSort(array).desc(v => v[property]);
        }

        return fastSort(array).asc(v => v[property]);
    }
}
