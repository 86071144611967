import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div(props => `

    margin-bottom: ${props.theme.margin.normal};
`);

const Label = styled.div(props => `

    padding-bottom: ${props.theme.padding.normal};
    ${props.error !== false && `color: ${props.theme.color.negative.normal}`}
`);

const Container = styled.div(props => `

    display: flex;
    justify-content: space-around;

    & > div > div:last-of-type {
        
        padding: 0 ${props.theme.padding.medium} 0 ${props.theme.padding.medium};
    }

    & > div:last-of-type > div:last-of-type {
        
        padding-right: 0;
    }
`);

const Item = styled.div(props => `

    display: flex;
    align-items: center;
`);

const Input = styled.div(props => `

    display: flex;
    flex-basis: ${props.theme.width.medium};
    align-items: center;
    justify-content: center;
    width: ${props.theme.width.medium};
    height: ${props.theme.height.medium};
    border-radius: 1.1em;
    background: ${props.theme.backgroundColor.core.tertiary};
    border: 1px solid ${props.theme.borderColor.core.primary};
    cursor: pointer;
`);

const Selected = styled.div(props => `

    display: ${props.checked === true ? `block` : `none`};
    width: 0.6em;
    height: 0.6em;
    border-radius: 0.3em;
    background: black;
`);

function Radio(props){

    function isChecked(v){

        if(v === props.value){

            return true;
        }

        return false;
    }

    function toggle(v){

        props.onChange({name: props.name, value: v});
    }

    return (

        <Wrapper style={props.style}>

            {props.label !== undefined && <Label error={props.error}>{props.label}:</Label>}

            <Container>

                {props.options.map((v, k) => {

                    return (

                        <Item key={k}>

                            <Input onClick={() => toggle(v.value)}>

                                <Selected checked={isChecked(v.value)}></Selected>

                            </Input>

                            <div>{v.label}</div>

                        </Item>
                    );
                })}

            </Container>

        </Wrapper>
    );
}

Radio.defaultProps = {

    error: false
}

export default Radio;
