import parseRoutes from 'library/parseRoutes';
import request from 'library/request';
import config from 'config';
import {sort} from 'library/helpers';

// Dynamically import modules and fetch dependencies by path
async function load(engine, path, options){

    if(options === undefined){

        options = {};
    }

    // By default autolad is enabled
    if(options.autoload === undefined){

        options.autoload = true;
    }

    // By default cache is not used
    if(options.cache === undefined){

        options.cache = false;
    }

    // Get routes
    const router = parseRoutes(engine.current.routes, path);

    if(router === false){

        if(process.env.NODE_ENV === 'development'){

            console.error('Path not found:', path);
        }

        return Promise.reject({notFound: true});
    }

    // Get next side
    const [side] = router.routes[router.routes.length - 1].component.split('/');

    // Theme modules
    const theme = side + '.' + engine.current.mode + '@theme';
    
    if(engine.current.imports.get(theme) === undefined){

        engine.current.imports.set(theme, {from: side + '.' + engine.current.mode + '.js', type: 'theme'});
    }

    // Language modules (components)
    const translation = engine.current.lang + '@' + side;

    if(engine.current.imports.get(translation) === undefined){

        engine.current.imports.set(translation, {from: side + '/' + engine.current.lang + '.json', type: 'lang'});
    }

    // Check privileges for matched routes
    router.routes.forEach(route => {

        if(route.group !== false){

            const groups = [];

            if(Array.isArray(route.group)){

                route.group.forEach(group => {

                    groups.push(group);
                });
            }

            else {

                groups.push(route.group);
            }

            let match = false;

            groups.forEach(group => {

                if(engine.current.state.groups.indexOf(group) !== -1){

                    match = true;
                }
            });

            if(match === false){

                engine.current.setForbidden(path);
            }
        }
    });

    if(engine.current.isForbidden(path) === false){

        router.routes.forEach(route => {

            // Route modules
            if(engine.current.imports.get(route.component) === undefined){

                engine.current.imports.set(route.component, {from: route.component, type: 'component'}); // Add module location for component
            }

            // Endpoint modules
            engine.current.imports.set(side + '@endpoints', {from: side, type: 'endpoint'});
        });
    }

    // Load modules
    const modules = [];

    engine.current.imports.forEach((importable, k) => {

        // Skip module that is already loaded
        if(importable.module === undefined){

            if(importable.type === 'endpoint'){

                modules.push(import(/* webpackChunkName: "[request]" */ 'endpoints/' + importable.from).then(

                    // Module loaded successfully
                    module => {

                        importable.module = module.default; // Add imported module
                        engine.current.imports.set(k, importable);
                    },

                    // Loading of module failed
                    error => {

                        engine.current.imports.delete(k); // Remove broken module from imports

                        return Promise.reject({error: error.message, network: true});
                    }
                ));
            }

            else if(importable.type === 'component'){

                modules.push(import(/* webpackChunkName: "[request]" */ 'components/' + importable.from).then(

                    // Module loaded successfully
                    module => {

                        importable.module = module.default; // Add imported module
                        engine.current.imports.set(k, importable);
                    },

                    // Loading of module failed
                    error => {

                        engine.current.imports.delete(k); // Remove broken module from imports

                        return Promise.reject({error: error.message, network: true});
                    }
                ));
            }

            else if(importable.type === 'theme'){

                modules.push(import(/* webpackChunkName: "[request]" */ 'themes/' + importable.from).then(

                    // Module loaded successfully
                    module => {

                        importable.module = module.default; // Add imported module
                        engine.current.imports.set(k, importable);
                    },

                    // Loading of module failed
                    error => {

                        engine.current.imports.delete(k); // Remove broken module from imports

                        return Promise.reject({error: error.message, network: true});
                    }
                ));
            }

            else if(importable.type === 'lang'){

                modules.push(import(/* webpackChunkName: "[request]" */ 'lang/' + importable.from).then(

                    // Module loaded successfully
                    module => {

                        importable.module = module.default; // Add imported module
                        engine.current.imports.set(k, importable);
                    },

                    // Loading of module failed
                    error => {

                        engine.current.imports.delete(k); // Remove broken module from imports

                        return Promise.reject({error: error.message, network: true});
                    }
                ));
            }
        }
    });

    // After modules has been loaded fetch dependencies
    return Promise.all(modules).then(

        () => {

            // Skip loading dedendencies if autolad is disabled
            if(options.autoload === false){

                return Promise.resolve();
            }

            const dependencies = [];

            // Get most inner params from nested routes
            function getParams(routes){

                let params = false;

                routes.forEach(v => {

                    if(v.subroutes.length !== 0){

                        params = getParams(v.subroutes); 
                    }

                    params = v.params;
                });

                return params;
            }

            const params = getParams(router.routes);
            const results = [];

            let requestOrder = 0;

            router.routes.forEach(route => {

                if(engine.current.isForbidden(path) === false){

                    const imported = engine.current.imports.get(side + '@endpoints');

                    route.dependencies.forEach(dependency => {

                        // Method here is the function name (not GET etc)
                        if(imported.module[dependency.method] === undefined){

                            if(process.env.NODE_ENV === 'development'){

                                console.error('Method does not exist:', dependency.method, imported.module);
                            }
                        }

                        let dependencyOptions = imported.module[dependency.method];

                        // If dependency options is callback function call it with params
                        if(typeof dependencyOptions === 'function'){

                            dependencyOptions = dependencyOptions(params);
                        }

                        if(dependencyOptions.method === undefined){

                            // Set GET as default if not set
                            dependencyOptions.method = 'GET';
                        }

                        requestOrder++;
                        dependencyOptions.requestOrder = requestOrder;

                        // Fetch dependencies
                        dependencies.push(request(dependencyOptions).then(

                            result => {

                                if(result.error){

                                    // Check errors
                                    const error = result.status;

                                    if(result.status.code === 400){

                                        error.badRequest = true;
                                    }

                                    return Promise.reject(error);
                                }

                                if(result.body === undefined){

                                    console.error('Response body is not set');
                                }

                                else if(result.body.data === undefined){

                                    console.error('Response data is not set');
                                }

                                if(result.body.data === null){

                                    console.error('Data is NULL:', dependencyOptions);
                                }

                                else {

                                    const data = Array.isArray(result.body.data) ? result.body.data : [result.body.data];

                                    results.push({

                                        requestOrder: dependencyOptions.requestOrder,
                                        storeName: dependency.storeName,
                                        data: data
                                    }); 
                                }
                            },

                            error => {

                                if(error.forbidden === true){

                                    engine.current.setForbidden(path);
                                }

                                return Promise.reject(error);
                            }
                        ));
                    });
                }
            });

            return Promise.all(dependencies).then(

                () => {

                    if(router.routes.length === 0){

                        return Promise.reject({notFound: true});
                    }

                    else {

                        // Do not start with empty stores if cache is enabled
                        const stores = options.cache === true ? engine.current.state.stores : {};

                        // Add data into stores by request order of the routes
                        sort(results, 'asc', 'requestOrder').forEach(v => {

                            const store = stores[v.storeName] !== undefined ? stores[v.storeName] : new Map();

                            v.data.forEach(v => {

                                if(v.uuid === undefined){

                                    console.error('UUID is not set');
                                }

                                store.set(v.uuid, v);
                            });

                            stores[v.storeName] = store;
                        });

                        return Promise.resolve(stores);
                    }
                },

                error => {

                    return Promise.reject(error);
                }
            );
        },

        error => {

            console.error('Modules not resolved:', error);

            return Promise.reject(error);
        }
    );
}

export default load;
