import React from 'react';
import styled from 'styled-components';

const Container = styled.button(props => `

    padding-left: ${props.theme.padding.medium};
    padding-right: ${props.theme.padding.medium};
    background: linear-gradient(${props.theme.backgroundColor.sub.primary}, ${props.theme.backgroundColor.sub.secondary});
    border: 1px solid ${props.theme.backgroundColor.sub.secondary};
    color: ${props.theme.color.sub.primary};
    border-radius: ${props.theme.borderRadius.small};
    height: ${props.theme.height.medium};
    outline: 0;
    width: 100%;
    cursor: pointer;
    margin-bottom: ${props.theme.margin.medium};
    white-space: nowrap;

    @media(hover: hover) and (pointer: fine){

        &:hover {

            background: linear-gradient(${props.theme.backgroundColor.positive.primary}, ${props.theme.backgroundColor.positive.secondary});
            border: 1px solid ${props.theme.backgroundColor.positive.primary};
        }
    }
`);

function Button(props){

    let type = props.type;

    if(type === undefined){

        if(props.onClick !== undefined){

            type = "button";
        }

        else {

            type = "submit";
        }
    }

    return (

        <Container style={props.style} onClick={props.onClick} type={type}>{props.children}</Container>
    );
}

export default Button;
